export const EVENT_LISTENERS = {
  BIP_EDITOR: {
    PRESENTATION_MODE_SHOWN: "presentationModeShown",
    PRESENTATION_MODE_HIDDEN: "presentationModeHidden",
    BEHAVIOR_NODE_STRATEGY_REMOVED: "behaviorNodeStrategyRemoved",
    INPUT_FOCUSED: "inputFocused",
    INPUT_BLURRED: "inputBlurred",
  },
  ZOOM_IMAGE_CHANGED: "zoomImageChanged",
  SNACKBAR: "snackbar",
}

export function dispatchCustomEvent(eventName, detail) {
  const event = new CustomEvent(eventName, { detail })
  window.dispatchEvent(event)
}
