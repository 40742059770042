export function smoothZoom(container, zoomInstance, isZoomIn) {
  const rect = container.getBoundingClientRect()
  const cx = rect.width / 2
  const cy = rect.height / 2
  const zoomBy = isZoomIn ? 1.5 : 0.5
  zoomInstance.smoothZoom(20, 20, zoomBy)
}

const MOBILE_BROWSERS =
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i

export function isMobile() {
  return MOBILE_BROWSERS.test(navigator.userAgent)
}
