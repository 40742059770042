export const STYLES = {
  STRATEGY: {
    IN_EDITOR: "bip-strategy-tile--in-editor",
    IN_SEARCH: "bip-strategy-tile--in-search",
    DRAGGING: "bip-strategy-tile--dragging",
    GHOST: "bip-strategy-tile--ghost",
  },
  BEHAVIOR_NODE: {
    DRAGGED_IN: "behavior-node--dragging",
    GRID_3: "behavior-node--grid-3",
    GRID_2: "behavior-node--grid-2",
  },
  HIGHLIGHTED: "bip-hovering-item",
  SELECT_NONE: "select-none",
}

export const QUERY_SELECTORS = {
  DRAGGING_ITEM: ".sortable-item",
  BIP_STRATEGY_TILE: ".bip-strategy-tile",
  BIP_EDITOR: ".bip-editor",
}
