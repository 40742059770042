import { Controller } from "@hotwired/stimulus"
import { EVENT_LISTENERS, dispatchCustomEvent } from "../util/event_listeners"

export default class extends Controller {
  static targets = ["image", "loading"]

  imageTargetConnected() {
    this.imageTarget.classList.remove("hidden")
    this.handleImageLoad = this.handleImageLoad.bind(this)
    this.imageTarget.addEventListener("load", this.handleImageLoad)
  }

  handleImageLoad() {
    this.loadingTarget.classList.add("hidden")
    this.imageTarget.removeEventListener("load", this.handleImageLoad)
  }

  close() {
    this.element.classList.add("hidden")
    this.loadingTarget.classList.remove("hidden")
    this.imageTarget.classList.add("hidden")
    dispatchCustomEvent(EVENT_LISTENERS.ZOOM_IMAGE_CHANGED)
  }
}
