import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "hint", "icon"]

  onInput() {
    if (this.hasHintTarget) {
      const isFocused = this.inputTarget.matches(":focus")
      const hasValue = this.inputTarget.value.length > 0
      const isLengthValid = this.inputTarget.value.length < 8
      const hasErrorClass = this.inputTarget.classList.contains("error")

      if (hasErrorClass && this.hasErrorHint()) {
        this.removeErrorHint()
      }

      if (isFocused && hasValue && isLengthValid && !hasErrorClass) {
        this.showHint()
      } else {
        this.hideHint()
      }
    }
  }

  onBlur() {
    if (this.hasHintTarget) {
      this.hideHint()
    }
  }

  onToggle() {
    const isPasswordType = this.inputTarget.type === "password"

    if (isPasswordType) {
      this.showPassword()
    } else {
      this.hidePassword()
    }
  }

  showHint() {
    this.hintTarget.classList.remove("hidden")
  }

  hideHint() {
    this.hintTarget.classList.add("hidden")
  }

  showPassword() {
    this.inputTarget.type = "text"
    this.toggleEyeIcon("fa-eye-slash", "fa-eye")
  }

  hidePassword() {
    this.inputTarget.type = "password"
    this.toggleEyeIcon("fa-eye", "fa-eye-slash")
  }

  toggleEyeIcon(removeClass, addClass) {
    this.iconTarget.classList.remove(removeClass)
    this.iconTarget.classList.add(addClass)
  }

  hasErrorHint() {
    return (
      this.inputTarget.nextElementSibling &&
      this.inputTarget.nextElementSibling.classList.contains(
        "form-hint",
        "error"
      )
    )
  }

  removeErrorHint() {
    const errorHint = this.inputTarget.nextElementSibling
    errorHint.remove()
    this.inputTarget.classList.remove("error")
  }
}
