import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const trigger = document.getElementById("first-time-guide-button")

    if (trigger) {
      this.timeoutId = setTimeout(() => {
        trigger.click()
      }, 300)
    }
  }

  disconnect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }
}
