import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Lines from "./editor/_lines"
import BehaviorNodeStrategyManager from "./editor/_behavior_node_strategy_manager"
import BehaviorNodeHandler from "./editor/_behavior_node_handler"
import { STYLES, QUERY_SELECTORS } from "./editor/_util"
import { EVENT_LISTENERS, dispatchCustomEvent } from "../util/event_listeners"
import Zoom from "./editor/_zoom"
import { put } from "@rails/request.js"
import handleRequest from "../util/handle_request"

const MAX_STRATEGIES_PER_NODE = 9
class BehaviorNodeStrategyHandler {
  constructor() {
    this.manager = new BehaviorNodeStrategyManager()
  }

  async create(event) {
    try {
      const response = await this.manager.create({
        strategy_id: event.item.dataset.strategyId,
        behavior_node_id: event.to.dataset.behaviorNodeId,
        row_order_position: event.newIndex - 1,
      })
      event.item.dataset.id = response.id
      event.item.dataset.behaviorNodeId = response.behavior_node_id
    } catch {
      event.item.remove()
    }
  }

  async update(event) {
    await this.manager.update(event.item.dataset.id, {
      row_order_position: event.newIndex - 1,
      behavior_node_id: event.to.dataset.behaviorNodeId,
    })
  }
}

export default class extends Controller {
  static targets = [
    "strategies",
    "container",
    "behaviorNode1",
    "behaviorNode2",
    "behaviorNode3",
    "behaviorNode4",
    "zoomIn",
    "zoomOut",
    "behaviorOverviewModal",
  ]
  static values = {
    isPresentationModeInitial: { type: Boolean },
    bipId: { type: Number },
  }

  connect() {
    this.isPresentationMode = this.isPresentationModeInitialValue
    this.isPreventingScroll = false
    this.initializeEventListeners()
    this.sortableStrategies = this.initializeSortableStrategies()
    const behaviorNodeMapping = {
      behaviorNode1: this.behaviorNode1Target,
      behaviorNode2: this.behaviorNode2Target,
      behaviorNode3: this.behaviorNode3Target,
      behaviorNode4: this.behaviorNode4Target,
    }
    this.lines = new Lines(behaviorNodeMapping)
    this.panzoom = new Zoom({
      container: this.containerTarget,
      zoomIn: this.zoomInTarget,
      zoomOut: this.zoomOutTarget,
      lines: this.lines,
    })
    this.behaviorNodeHandler = new BehaviorNodeHandler(behaviorNodeMapping)
    this.behaviorNodeStrategyHandler = new BehaviorNodeStrategyHandler()
    this.sortableBehaviorNodes = Object.values(behaviorNodeMapping).map(
      (behaviorNodeTarget) => {
        return this.initializeSortableBehaviorNodes(behaviorNodeTarget)
      }
    )
    // Adding this in classList's seems to be overwritten somehow?
    document.body.style.overflow = "hidden"
  }

  disconnect() {
    this.sortableBehaviorNodes.forEach((element) => {
      element.destroy()
    })
    this.sortableStrategies.destroy()
    this.lines.destroy()
    this.panzoom.disconnect()
    this.disconnectEventListeners()
  }

  strategiesTargetConnected() {
    this.sortableStrategies = this.initializeSortableStrategies()
  }

  async showPresentationMode() {
    if (!this.isPresentationMode) {
      this.element.classList.add("bip-editor--presentation")
      this.element.classList.remove("bip-editor--edit")
      this.sortableBehaviorNodes.forEach((sbn) => {
        sbn.option("disabled", true)
      })
      dispatchCustomEvent(EVENT_LISTENERS.BIP_EDITOR.PRESENTATION_MODE_SHOWN)
      this.isPresentationMode = true
      await handleRequest(put, `/bips/${this.bipIdValue}/present`)
    }
  }

  hidePresentationMode(event) {
    if (this.isPresentationMode && !this.isPresentationModeInitialValue) {
      event.preventDefault()
      this.element.classList.add("bip-editor--edit")
      this.element.classList.remove("bip-editor--presentation")
      this.sortableBehaviorNodes.forEach((sbn) => {
        sbn.option("disabled", false)
      })
      dispatchCustomEvent(EVENT_LISTENERS.BIP_EDITOR.PRESENTATION_MODE_HIDDEN)
      this.isPresentationMode = false
    }
  }

  showRelated(event) {
    if (this.isPresentationMode) {
      this.behaviorNodeHandler.showRelated(event.params.element)
      this.lines.showRelated(event.params.element)
    }
  }

  hideRelated() {
    if (this.isPresentationMode) {
      this.behaviorNodeHandler.hideRelated()
      this.lines.hideRelated()
    }
  }

  initializeSortableStrategies() {
    return Sortable.create(this.strategiesTarget, {
      sort: false,
      group: {
        name: "shared",
        pull: "clone",
        put: () => {
          return false
        },
      },
      draggable: QUERY_SELECTORS.DRAGGING_ITEM,
      forceFallback: true,
      fallbackClass: STYLES.STRATEGY.GHOST,
      onStart: (event) => {
        this.isPreventingScroll = true
        event.item.classList.add(STYLES.STRATEGY.DRAGGING)
        document.body.classList.add(STYLES.SELECT_NONE)
      },
      onClone: (event) => {
        // There's a bug mixing up .item and .clone so we have to add it in both
        // onStart and onClone so that it doesn't remove the class before it's dropped
        // https://github.com/SortableJS/Sortable/issues/1515
        event.clone.classList.add(STYLES.STRATEGY.DRAGGING)
        event.item.classList.add(STYLES.STRATEGY.DRAGGING)
      },
      onMove: (event) => {
        this.behaviorNodeHandler.highlight(event.to)
        if (event.to !== event.from) {
          event.dragged.classList.add(STYLES.STRATEGY.IN_EDITOR)
          event.dragged.classList.remove(STYLES.STRATEGY.IN_SEARCH)
        }
      },
      onEnd: async (event) => {
        this.isPreventingScroll = false
        document.body.classList.remove(STYLES.SELECT_NONE)
        event.item.classList.remove(STYLES.STRATEGY.DRAGGING)
        event.clone.classList.remove(STYLES.STRATEGY.DRAGGING)

        if (event.to !== event.from) {
          this.behaviorNodeHandler.removeHighlight(event.to)
          this.behaviorNodeHandler.updateGrid(event.to)
          this.lines.refresh()
          await this.behaviorNodeStrategyHandler.create(event)
        }
      },
    })
  }

  initializeSortableBehaviorNodes(behaviorNode) {
    return Sortable.create(behaviorNode, {
      disabled: this.isPresentationMode,
      draggable: QUERY_SELECTORS.DRAGGING_ITEM,
      group: {
        name: "shared",
        put: (to, from, dragEl) => {
          return (
            this.isNotDuplicateSortableItem(to, dragEl) &&
            this.isNotExceedingMaxStrategies(to)
          )
        },
      },
      forceFallback: true,
      fallbackClass: STYLES.STRATEGY.GHOST,
      onStart: () => {
        this.isPreventingScroll = true
        this.panzoom.pause()
      },
      onMove: (event) => {
        event.dragged.classList.add(STYLES.STRATEGY.DRAGGING)
        this.behaviorNodeHandler.highlight(event.to)
      },
      onEnd: async (event) => {
        this.panzoom.resume()
        this.isPreventingScroll = false

        event.item.classList.remove(STYLES.STRATEGY.DRAGGING)
        this.behaviorNodeHandler.updateGrid(event.to)
        this.behaviorNodeHandler.updateGrid(event.from)
        this.behaviorNodeHandler.removeHighlight(event.to)
        this.lines.refresh()
        if (event.to !== event.from || event.oldIndex !== event.newIndex) {
          await this.behaviorNodeStrategyHandler.update(event)
        }
      },
    })
  }

  initializeEventListeners() {
    this.refreshLinesAndBehaviorNode =
      this.refreshLinesAndBehaviorNode.bind(this)
    this.refreshLines = this.refreshLines.bind(this)
    this.preventScroll = this.preventScroll.bind(this)
    window.addEventListener(
      EVENT_LISTENERS.BIP_EDITOR.BEHAVIOR_NODE_STRATEGY_REMOVED,
      this.refreshLinesAndBehaviorNode
    )
    window.addEventListener(
      EVENT_LISTENERS.BIP_EDITOR.INPUT_BLURRED,
      this.refreshLines
    )
    window.addEventListener("scroll", this.preventScroll)
  }

  disconnectEventListeners() {
    window.removeEventListener(
      EVENT_LISTENERS.BIP_EDITOR.BEHAVIOR_NODE_STRATEGY_REMOVED,
      this.refreshLinesAndBehaviorNode
    )
    window.removeEventListener(
      EVENT_LISTENERS.BIP_EDITOR.INPUT_BLURRED,
      this.refreshLines
    )
    window.removeEventListener("scroll", this.preventScroll)
  }

  showBehaviorOverviewModal() {
    this.behaviorOverviewModalTarget.classList.remove("invisible")
  }

  hideBehaviorOverviewModal() {
    this.behaviorOverviewModalTarget.classList.add("invisible")
  }

  refreshLines() {
    this.lines.refresh()
  }

  preventScroll(event) {
    if (this.isPreventingScroll) {
      event.preventDefault()
      window.scrollTo(0, 0)
    }
  }

  refreshLinesAndBehaviorNode(event) {
    const behaviorNodeId = event.detail.behaviorNodeId
    if (!behaviorNodeId) {
      console.error("No behaviorNodeId given")
    } else {
      this.behaviorNodeHandler.updateGrid(
        this.behaviorNodeHandler.find(behaviorNodeId)
      )
      this.lines.refresh()
    }
  }

  isNotDuplicateSortableItem(to, dragEl) {
    for (let i = 0; i < to.el.children.length; i++) {
      if (to.el.children[i].dataset.strategyId === dragEl.dataset.strategyId) {
        return false
      }
    }
    return true
  }

  isNotExceedingMaxStrategies(to) {
    const strategiesCount = to.el.querySelectorAll(
      QUERY_SELECTORS.BIP_STRATEGY_TILE
    ).length
    return strategiesCount < MAX_STRATEGIES_PER_NODE
  }
}
