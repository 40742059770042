import { EVENT_LISTENERS, dispatchCustomEvent } from "./event_listeners"

export const headers = { Accept: "application/json" }

const handleRequest = async (requestMethod, url, body) => {
  try {
    const response = await requestMethod(url, {
      body,
      headers: { Accept: "application/json" },
    })
    try {
      const json = await response.json
      if (response.ok) {
        return json
      } else {
        throw json
      }
    } catch {
      if (!response.ok) {
        throw "Error failed with no response"
      }
    }
  } catch (error) {
    console.error("Error processing request:", error)
    dispatchCustomEvent(EVENT_LISTENERS.SNACKBAR)
    throw error
  }
}

export default handleRequest
