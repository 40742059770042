import { post, put, destroy } from "@rails/request.js"
import handleRequest from "../../util/handle_request"

export default class BehaviorNodeStrategyManager {
  async create(data) {
    return handleRequest(post, "/behavior_node_strategies", data)
  }

  async update(id, data) {
    return handleRequest(put, `/behavior_node_strategies/${id}`, data)
  }

  async destroy(id) {
    return handleRequest(destroy, `/behavior_node_strategies/${id}`)
  }
}
