import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"
import handleRequest from "../util/handle_request"
import { EVENT_LISTENERS, dispatchCustomEvent } from "../util/event_listeners"
import { QUERY_SELECTORS } from "./editor/_util"

export default class extends Controller {
  static targets = ["input", "icon"]
  static values = {
    field: String,
    url: String,
    placeholder: String,
    elementClass: String,
  }

  connect() {
    this.initialInput = this.getInputValue()
    this.addPlaceholder()
    this.handlePaste = this.handlePaste.bind(this)
    this.showPresentationMode = this.showPresentationMode.bind(this)
    this.hidePresentationMode = this.hidePresentationMode.bind(this)
    this.inputTarget.addEventListener("paste", this.handlePaste)
    window.addEventListener(
      EVENT_LISTENERS.BIP_EDITOR.PRESENTATION_MODE_SHOWN,
      this.showPresentationMode
    )
    window.addEventListener(
      EVENT_LISTENERS.BIP_EDITOR.PRESENTATION_MODE_HIDDEN,
      this.hidePresentationMode
    )
  }

  disconnect() {
    window.removeEventListener(
      EVENT_LISTENERS.BIP_EDITOR.PRESENTATION_MODE_SHOWN,
      this.showPresentationMode
    )
    window.removeEventListener(
      EVENT_LISTENERS.BIP_EDITOR.PRESENTATION_MODE_HIDDEN,
      this.hidePresentationMode
    )
    this.inputTarget.removeEventListener("paste", this.handlePaste)
  }

  show() {
    this.inputTarget.setAttribute("contentEditable", true)
    this.iconTarget.classList.add("hidden")
    this.focusAndRemovePlaceholder()

    /// This needs to be wrapped in a setTimeout
    // to ensure that the focus and cursor positioning
    // occur after the element is fully rendered
    setTimeout(() => {
      this.focus()
    }, 0)
  }

  async submit(event) {
    if (event.key === "Enter" || event.key === "Escape") {
      event.preventDefault()
      await this.inputTarget.blur()
    }
  }

  async hide() {
    if (this.initialInput !== this.getInputValue()) {
      await this.save()
    }
    if (this.hasIconTarget) {
      this.inputTarget.setAttribute("contentEditable", false)
      this.iconTarget.classList.remove("hidden")
    }
    this.addPlaceholder()
    dispatchCustomEvent(EVENT_LISTENERS.BIP_EDITOR.INPUT_BLURRED)
  }

  async save() {
    try {
      await handleRequest(put, this.getUrl(), {
        [this.fieldValue]: this.getInputValue(),
      })
    } catch {
      this.inputTarget.textContent = this.initialInput
    }
  }

  showPresentationMode() {
    if (!this.hasIconTarget) {
      this.inputTarget.setAttribute("contenteditable", false)
    } else if (this.inputTarget.innerText === this.placeholderValue) {
      this.element.classList.add("hidden")
    }
  }

  hidePresentationMode() {
    if (!this.hasIconTarget) {
      this.inputTarget.setAttribute("contenteditable", true)
    } else if (this.inputTarget.innerText === this.placeholderValue) {
      this.element.classList.remove("hidden")
    }
  }

  getInputValue() {
    return this.inputTarget.textContent.trim()
  }

  getUrl() {
    const id = this.inputTarget.closest(this.elementClassValue).dataset.id
    if (!id) {
      console.error("This element does not have an ID")
    }

    return this.urlValue.replace(":id", id)
  }

  focus() {
    this.inputTarget.focus()
    const range = document.createRange()
    const selection = window.getSelection()
    range.selectNodeContents(this.inputTarget)
    range.collapse(false)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  focusAndRemovePlaceholder() {
    dispatchCustomEvent(EVENT_LISTENERS.BIP_EDITOR.INPUT_FOCUSED)

    if (
      this.hasPlaceholderValue &&
      this.inputTarget.innerText === this.placeholderValue
    ) {
      this.inputTarget.innerText = ""
    }
  }

  addPlaceholder() {
    if (this.hasPlaceholderValue && this.inputTarget.innerText === "") {
      this.inputTarget.innerText = this.placeholderValue
    }
  }

  handlePaste(e) {
    // This can be removed when Firefox supports contenteditable=plaintext-only
    e.preventDefault()

    const text = e.clipboardData
      ? (e.originalEvent || e).clipboardData.getData("text/plain")
      : // For IE
      window.clipboardData
      ? window.clipboardData.getData("Text")
      : ""

    const range = document.getSelection().getRangeAt(0)
    range.deleteContents()

    const textNode = document.createTextNode(text)
    range.insertNode(textNode)
    range.selectNodeContents(textNode)
    range.collapse(false)

    const selection = window.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)
  }
}
