import LeaderLine from "leader-line"
import { STYLES } from "./_util"

export default class Lines {
  constructor({ behaviorNode1, behaviorNode2, behaviorNode3, behaviorNode4 }) {
    const line1to2 = new LeaderLine(
      behaviorNode1,
      LeaderLine.pointAnchor(behaviorNode2, {
        x: "-1%",
        y: "45%",
      }),
      { startPlug: "disc", color: "#00AAA6", size: 3 }
    )
    line1to2.startSocketGravity = [20, 0]
    line1to2.endSocketGravity = [-20, 0]

    // Getting the leader line elements the easiest is through this method
    this.svgLine1to2 = document.querySelector("body>.leader-line:last-child")

    const line3to2 = new LeaderLine(
      LeaderLine.pointAnchor(behaviorNode3, {
        x: "100%",
        y: "45%",
      }),
      LeaderLine.pointAnchor(behaviorNode2, {
        x: "-1%",
        y: "56%",
      }),
      { startPlug: "disc", color: "#00AAA6", size: 3 }
    )
    line3to2.startSocketGravity = [90, 0]
    line3to2.endSocketGravity = [-90, 0]
    this.svgLine3to2 = document.querySelector("body>.leader-line:last-child")

    const line3to4 = new LeaderLine(
      LeaderLine.pointAnchor(behaviorNode3, {
        x: "100%",
        y: "52%",
      }),
      LeaderLine.pointAnchor(behaviorNode4, {
        x: "-1%",
        y: "50%",
      }),
      { startPlug: "disc", color: "#F0542A", size: 3 }
    )
    line3to4.startSocketGravity = [20, 0]
    line3to4.endSocketGravity = [-20, 0]
    this.svgLine3to4 = document.querySelector("body>.leader-line:last-child")

    this.lines = [line1to2, line3to2, line3to4]
    this.svgLines = [this.svgLine1to2, this.svgLine3to2, this.svgLine3to4]
  }

  refresh() {
    this.lines.forEach((element) => {
      element.position()
    })
  }

  destroy() {
    // Workaround
    // https://github.com/anseki/leader-line/issues/246
    this.svgLines.forEach((element) => {
      document.body.appendChild(element)
    })
    this.lines.forEach((element) => {
      element.remove()
    })
    this.lines = []
  }

  showRelated(behaviorNode) {
    const highlights = {
      behaviorNode1: [this.svgLine3to2, this.svgLine3to4],
      behaviorNode2: [this.svgLine3to4],
      behaviorNode3: [this.svgLine1to2],
      behaviorNode4: [this.svgLine1to2, this.svgLine3to2],
    }
    highlights[behaviorNode].forEach((element) => {
      element.classList.add(STYLES.HIGHLIGHTED)
    })
  }

  hideRelated() {
    this.svgLines.forEach((element) => {
      element.classList.remove(STYLES.HIGHLIGHTED)
    })
  }
}
