import { Controller } from "@hotwired/stimulus"
import intlTelInput from "intl-tel-input/intlTelInputWithUtils"

export default class extends Controller {
  connect() {
    const input = document.querySelector("#iti");
    intlTelInput(input, {
      initialCountry: "us",
      separateDialCode: true,
      strictMode: true,
      hiddenInput: (_) => { return {phone: "mobile_number", country: "country_code"} },
    });
  }
}
