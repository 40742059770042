import { Controller } from "@hotwired/stimulus"
import panzoom from "panzoom"
import { EVENT_LISTENERS } from "./util/event_listeners"
import { isMobile, smoothZoom } from "./util/zoom"

export default class extends Controller {
  static targets = ["zoomIn", "zoomOut", "zoomElement"]

  connect() {
    this.zoomInstance = panzoom(this.zoomElementTarget, {
      minZoom: 1,
      bounds: true,
      boundsPadding: 1,
      onTouch: function (e) {
        return false
      },
    })
    this.zoomElementTarget.addEventListener("contextmenu", (event) => {
      event.preventDefault()
    })
    this.zoomInTarget.addEventListener("click", () => {
      smoothZoom(this.zoomElementTarget, this.zoomInstance, true)
    })
    this.zoomOutTarget.addEventListener("click", () => {
      smoothZoom(this.zoomElementTarget, this.zoomInstance, false)
    })
    window.addEventListener(EVENT_LISTENERS.ZOOM_IMAGE_CHANGED, () => {
      this.reset()
    })
  }

  disconnect() {
    window.removeEventListener(EVENT_LISTENERS.ZOOM_IMAGE_CHANGED, () => {
      this.reset()
    })
    this.zoomInTarget.removeEventListener("click", () => {
      smoothZoom(this.zoomElementTarget, this.zoomInstance, true)
    })
    this.zoomOutTarget.removeEventListener("click", () => {
      smoothZoom(this.zoomElementTarget, this.zoomInstance, false)
    })
    this.zoomInstance.dispose()
  }

  reset() {
    this.zoomInstance.moveTo(0, 0)
    this.zoomInstance.zoomAbs(0, 0, 1)
  }
}
