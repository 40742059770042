import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea", "submit"]

  checkLength() {
    const minlength = this.textareaTarget.getAttribute("minlength")
    if (minlength) {
      this.submitTarget.disabled =
        this.textareaTarget.value.length < Number(minlength)
    }
  }
}
