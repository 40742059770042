import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image", "loading"]

  imageTargetConnected() {
    this.handleImageLoad = this.handleImageLoad.bind(this)
    this.imageTarget.addEventListener("load", this.handleImageLoad)
  }

  handleImageLoad() {
    this.loadingTarget.classList.add("hidden")
    this.imageTarget.removeEventListener("load", this.handleImageLoad)
  }
}
