import { Controller } from "@hotwired/stimulus"
import Lines from "./editor/_lines"

export default class extends Controller {
  static targets = [
    "container",
    "behaviorNodes",
    "behaviorNode1",
    "behaviorNode2",
    "behaviorNode3",
    "behaviorNode4",
  ]

  connect() {
    // const topPadding = 20
    // let behaviorNodesRect = this.behaviorNodesTarget.getBoundingClientRect()
    // const containerHeight = this.containerTarget.getBoundingClientRect().height
    // let scale = 1

    // while (behaviorNodesRect.height > containerHeight - topPadding) {
    //   scale -= 0.01
    //   this.behaviorNodesTarget.style.transform = `scale(${scale})`
    //   behaviorNodesRect = this.behaviorNodesTarget.getBoundingClientRect()
    // }

    // if (scale !== 1) {
    //   this.behaviorNodesTarget.style.top = `${
    //     topPadding - (behaviorNodesRect.top + window.scrollY)
    //   }px`
    // }

    const behaviorNodeMapping = {
      behaviorNode1: this.behaviorNode1Target,
      behaviorNode2: this.behaviorNode2Target,
      behaviorNode3: this.behaviorNode3Target,
      behaviorNode4: this.behaviorNode4Target,
    }
    this.lines = new Lines(behaviorNodeMapping)
    window.refreshLines = () => this.lines.refresh()
  }
}
