import { Controller } from "@hotwired/stimulus"
import { EVENT_LISTENERS, dispatchCustomEvent } from "./util/event_listeners"
import { SNACKBAR_TYPES } from "./snackbar_controller"

export default class extends Controller {
  connect() {
    this.initialValue = this.element.value
  }

  submit(event) {
    if (
      event.target.type !== "checkbox" &&
      event.target.value == this.initialValue
    ) {
      return
    }
    this.initialValue = event.target.value
    event.preventDefault()
    event.target.closest("form").requestSubmit()
    dispatchCustomEvent(EVENT_LISTENERS.SNACKBAR, {
      text: stimulus.snackbar.successfully_saved,
      type: SNACKBAR_TYPES.SUCCESS,
    })
  }
}
