import panzoom from "panzoom"
import { isMobile, smoothZoom } from "../../util/zoom"
import { EVENT_LISTENERS } from "../../util/event_listeners"

export default class Zoom {
  constructor({ container, zoomIn, zoomOut, lines }) {
    this.container = container
    this.zoomIn = zoomIn
    this.zoomOut = zoomOut
    this.lines = lines
    this.zoomInstance = panzoom(container, {
      minZoom: 0.5,
      smoothScroll: false,
      bounds: true,
      boundsPadding: 0.4,
      onTouch: function (e) {
        return false
      },
    })
    this.zoomInstance.on("transform", () => {
      this.lines.refresh()
    })
    this.zoomIn.addEventListener("click", () => {
      smoothZoom(this.container, this.zoomInstance, true)
    })
    this.zoomOut.addEventListener("click", () => {
      smoothZoom(this.container, this.zoomInstance, false)
    })
    window.addEventListener(EVENT_LISTENERS.BIP_EDITOR.INPUT_FOCUSED, () => {
      this.pause()
    })
    window.addEventListener(EVENT_LISTENERS.BIP_EDITOR.INPUT_BLURRED, () => {
      this.resume()
    })
  }

  disconnect() {
    this.zoomIn.removeEventListener("click", () => {
      smoothZoom(this.container, this.zoomInstance, true)
    })
    this.zoomOut.removeEventListener("click", () => {
      smoothZoom(this.container, this.zoomInstance, false)
    })
    this.zoomInstance.dispose()
  }

  pause() {
    this.zoomInstance.pause()
  }

  resume() {
    this.zoomInstance.resume()
  }
}
