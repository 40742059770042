import { QUERY_SELECTORS, STYLES } from "./_util"

export default class BehaviorNodeHandler {
  constructor({ behaviorNode1, behaviorNode2, behaviorNode3, behaviorNode4 }) {
    this.behaviorNode1 = behaviorNode1
    this.behaviorNode2 = behaviorNode2
    this.behaviorNode3 = behaviorNode3
    this.behaviorNode4 = behaviorNode4
    this.behaviorNodes = [
      behaviorNode1,
      behaviorNode2,
      behaviorNode3,
      behaviorNode4,
    ]
  }

  showRelated(behaviorNode) {
    const highlights = {
      behaviorNode1: [this.behaviorNode3, this.behaviorNode4],
      behaviorNode2: [this.behaviorNode4],
      behaviorNode3: [this.behaviorNode1],
      behaviorNode4: [this.behaviorNode1, this.behaviorNode2],
    }
    highlights[behaviorNode].forEach((element) => {
      element.classList.add(STYLES.HIGHLIGHTED)
    })
  }

  hideRelated() {
    this.behaviorNodes.forEach((element) => {
      element.classList.remove(STYLES.HIGHLIGHTED)
    })
  }

  updateGrid(element) {
    const items = element.querySelectorAll(QUERY_SELECTORS.DRAGGING_ITEM)
    if (items.length >= 3) {
      element.classList.add(STYLES.BEHAVIOR_NODE.GRID_3)
      element.classList.remove(STYLES.BEHAVIOR_NODE.GRID_2)
    } else if (items.length === 2) {
      element.classList.add(STYLES.BEHAVIOR_NODE.GRID_2)
      element.classList.remove(STYLES.BEHAVIOR_NODE.GRID_3)
    } else {
      element.classList.remove(STYLES.BEHAVIOR_NODE.GRID_2)
      element.classList.remove(STYLES.BEHAVIOR_NODE.GRID_3)
    }
  }

  highlight(behaviorNode) {
    // Makes sure all other nodes are not highlighted
    this.behaviorNodes.forEach((element) => {
      element.classList.remove(STYLES.BEHAVIOR_NODE.DRAGGED_IN)
    })
    behaviorNode.classList.add(STYLES.BEHAVIOR_NODE.DRAGGED_IN)
  }

  removeHighlight(behaviorNode) {
    behaviorNode.classList.remove(STYLES.BEHAVIOR_NODE.DRAGGED_IN)
  }

  find(behaviorNodeId) {
    return this.behaviorNodes.find((behaviorNode) => {
      return behaviorNode.dataset.behaviorNodeId === behaviorNodeId
    })
  }
}
