import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timer", "resend"]

  connect() {
    this.countdownTime = 60
    this.updateTimer()
    this.timerInterval = setInterval(this.updateTimer.bind(this), 1000)
  }

  disconnect() {
    clearInterval(this.timerInterval)
  }

  updateTimer() {
    let minutes = Math.floor(this.countdownTime / 60)
    let seconds = this.countdownTime % 60

    minutes = minutes < 10 ? "0" + minutes : minutes
    seconds = seconds < 10 ? "0" + seconds : seconds

    this.timerTarget.textContent = `${stimulus.send_otp.in} ${minutes}:${seconds}`
    this.countdownTime--

    if (this.countdownTime < 0) {
      this.handleTimerEnd()
    }
  }

  handleTimerEnd() {
    clearInterval(this.timerInterval)
    this.timerTarget.classList.add("hidden")
    this.resendTarget.classList.remove("hidden")
  }
}
