import { Controller } from "@hotwired/stimulus"
import BehaviorNodeStrategyManager from "./editor/_behavior_node_strategy_manager"
import { EVENT_LISTENERS, dispatchCustomEvent } from "../util/event_listeners"
import { insertConfirmModal } from "../../src/confirm"

export default class extends Controller {
  static targets = ["removeButton", "strategyForm"]

  connect() {
    this.behaviorNodeStrategyManager = new BehaviorNodeStrategyManager()
  }

  async remove() {
    const modal = insertConfirmModal(
      this.removeButtonTarget.dataset.turboConfirm,
      this.removeButtonTarget,
      this.removeButtonTarget
    )
    modal.querySelector("[data-behavior='cancel']").addEventListener(
      "click",
      () => {
        modal.remove()
      },
      { once: true }
    )
    modal.querySelector("[data-behavior='commit']").addEventListener(
      "click",
      async () => {
        modal.remove()
        await this.destroy()
      },
      { once: true }
    )
  }

  async destroy() {
    const behaviorNodeId = this.element.dataset.behaviorNodeId
    await this.behaviorNodeStrategyManager.destroy(this.element.dataset.id)
    this.element.remove()
    dispatchCustomEvent(
      EVENT_LISTENERS.BIP_EDITOR.BEHAVIOR_NODE_STRATEGY_REMOVED,
      { behaviorNodeId }
    )
  }

  open() {
    if (this.strategyFormTarget.action.includes("pricing")) {
      window.open(this.strategyFormTarget.action, "_blank")
    } else {
      this.strategyFormTarget.requestSubmit()
      document
        .getElementById("strategy-image-container")
        .classList.remove("hidden")
    }
  }
}
