import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
import handleRequest from "../util/handle_request"
import { EVENT_LISTENERS, dispatchCustomEvent } from "../util/event_listeners"
import { SNACKBAR_TYPES } from "../snackbar_controller"

export default class extends Controller {
  static targets = [
    "header",
    "hiddenTitle",
    "hiddenDescription",
    "titleInput",
    "descriptionInput",
    "addItem",
    "hiddenList",
    "closeButton",
    "saveButton",
    "deleteButton",
  ]
  static values = {
    bipId: Number,
  }

  edit(event) {
    this.currentIndex = event.params.index
    this.titleInputTarget.value =
      this.hiddenTitleTargets[this.currentIndex].value
    this.descriptionInputTarget.value =
      this.hiddenDescriptionTargets[this.currentIndex].value
    this.deleteButtonTarget.classList.remove("hidden")
    this.saveButtonTarget.innerText = stimulus.behavior_goals.update
    this.headerTarget.innerText = stimulus.behavior_goals.update
  }

  add() {
    // For some reason, this is being triggered on text field enter
    if (document.activeElement === this.addItemTarget) {
      this.currentIndex = null
      this.titleInputTarget.value = ""
      this.descriptionInputTarget.value = ""
      this.saveButtonTarget.disabled = true
      this.deleteButtonTarget.classList.add("hidden")
      this.saveButtonTarget.innerText = stimulus.behavior_goals.add
      this.headerTarget.innerText = stimulus.behavior_goals.add
    }
  }

  async save() {
    let newList = JSON.parse(this.hiddenListTarget.value)
    const goal = {
      title: this.titleInputTarget.value,
      description: this.descriptionInputTarget.value,
    }
    if (this.currentIndex != null) {
      newList[this.currentIndex] = goal
    } else {
      newList = [...newList, goal]
    }
    await this.update(newList)
  }

  async remove() {
    const newList = JSON.parse(this.hiddenListTarget.value)
    await this.update(
      newList.filter((item, index) => index !== this.currentIndex)
    )
    dispatchCustomEvent(EVENT_LISTENERS.SNACKBAR, {
      text: stimulus.snackbar.successfully_saved,
      type: SNACKBAR_TYPES.SUCCESS,
    })
  }

  async update(list) {
    await handleRequest(patch, `/bips/${this.bipIdValue}`, {
      bip: {
        overview_behavior_goals_list: list,
      },
    })
    this.closeButtonTarget.click()
    dispatchCustomEvent(EVENT_LISTENERS.SNACKBAR, {
      text: stimulus.snackbar.successfully_saved,
      type: SNACKBAR_TYPES.SUCCESS,
    })
  }

  checkInputs() {
    if (
      this.titleInputTarget.value.trim() === "" ||
      this.descriptionInputTarget.value.trim() === ""
    ) {
      this.saveButtonTarget.disabled = true
    } else {
      this.saveButtonTarget.disabled = false
    }
  }
}
