import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { listenToSubmit: { type: Boolean, default: true } }

  connect() {
    this.element.noValidate = true
    this.element.addEventListener("input", this.handleInputChange.bind(this))

    if (this.listenToSubmitValue) {
      this.element.addEventListener("submit", this.validate.bind(this))
    }
  }

  handleInputChange(e) {
    const inputElement = e.target
    if (
      this.isInputElement(inputElement) &&
      this.isRequiredInputWithValue(inputElement)
    ) {
      this.clearError(inputElement)
    }
  }

  validate(e) {
    const isValid = this.isFormValid()
    if (!isValid) {
      e.preventDefault()
      e.stopImmediatePropagation()
    }
  }

  isFormValid() {
    let isValid = true
    const requiredFieldSelectors = "textarea:required, input:required"
    const requiredFields = this.element.querySelectorAll(requiredFieldSelectors)
    requiredFields.forEach((field) => {
      const inputValue = field.value.trim()
      this.clearError(field)

      if (!field.disabled && !inputValue) {
        this.addError({ field, errorLabel: stimulus.form.error_blank })
        isValid = false
      }

      if (inputValue) {
        if (!this.validateField(field, inputValue)) {
          isValid = false
        }
      }
    })

    return isValid
  }

  validateField(field, inputValue) {
    if (field.type === "email" && !this.isValidEmail(inputValue)) {
      this.addError({ field, errorLabel: stimulus.form.error_invalid })
      return false
    } else if (
      field.type === "password" &&
      field.autocomplete == "new-password" &&
      !this.isValidPassword(inputValue)
    ) {
      this.addError({
        field,
        errorLabel: stimulus.form.error_password_length,
        showPrefix: false,
      })
      return false
    } else if (field.minLength && inputValue.length < field.minLength) {
      this.addError({
        field,
        errorLabel: stimulus.form.error_invalid,
      })
      return false
    } else if (field.type === "checkbox" && !field.checked) {
      this.addError({ field, errorLabel: stimulus.form.checkbox_acceptance })
      return false
    }

    return true
  }

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  isValidPassword(password) {
    const minLength = stimulus.form.minimum_password_length
    return minLength <= password.length
  }

  clearError(field) {
    const errorNodes = field.parentNode.querySelectorAll(".form-hint.error")
    errorNodes.forEach((element) => {
      element.remove()
    })
    field.classList.remove("error")
  }

  addError({ field, errorLabel, showPrefix = true }) {
    field.classList.add("error")
    const error = document.createElement("p")
    error.classList = "form-hint error"
    const label =
      field.previousElementSibling &&
      field.previousElementSibling.tagName === "LABEL"
        ? field.previousElementSibling.innerText
        : field.getAttribute("data-error-label")
    const prefixLabel = showPrefix ? `${label || "Field"} ` : ""
    error.innerHTML = `${prefixLabel}${errorLabel}`
    field.parentNode.appendChild(error)
  }

  isInputElement(element) {
    return (
      (element.tagName === "INPUT" || element.tagName === "TEXTAREA") &&
      element.type !== "hidden"
    )
  }

  isRequiredInputWithValue(element) {
    return element.required && element.value !== ""
  }
}
