import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "error", "hiddenInput", "button"]

  validateAndSubmit(event) {
    event.preventDefault()

    const emails = this.inputTarget.value
      .split(",")
      .map((email) => email.trim())
    const existingEmails = [
      ...document.getElementsByClassName("bip_share_email"),
    ].map((el) => el.textContent)
    const invalidEmails = emails.filter(
      (email) => !this.isValidEmail(email) || existingEmails.includes(email)
    )

    if (invalidEmails.length > 0) {
      this.showError()
    } else {
      this.hiddenInputTarget.value = emails.join(",")
      this.element.requestSubmit()
    }
  }

  isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  showError() {
    this.errorTarget.classList.remove("hidden")
  }

  resetAndForceLowercase(event) {
    this.errorTarget.classList.add("hidden")
    this.buttonTarget.disabled = event.target.value === ""
    this.inputTarget.value = event.target.value.toLowerCase()
  }
}
